import React from 'react';
import { Link } from 'gatsby';

import SEO from '../components/SEO';
import Layout from '../components/Layout';

const meta = [{
  name: 'robots',
  content: 'noindex,nofollow'
}];

const SuccessPage = () => (
  <Layout>
    <SEO title="Success" meta={meta} />
    <div className="container">
      <div className="grid justify-center">
        <div className="col-12 col-md-8 col-xlg-6">
          <h1>Thank you</h1>

          <p className="u-margin-top-full"><strong>Your referral has been sent to the FLOWS team.</strong></p>

          <p className="u-margin-top-full">We will contact your client within one working day.</p>

          <p className="u-margin-top-full">
            <Link to="/">Go back to the referral form</Link>
          </p>
        </div>
      </div>
    </div>
  </Layout>
);

export default SuccessPage;
